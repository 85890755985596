import axios from 'axios';
import { BASE_URL } from './config';

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    timeout: 30000,
});

export const setAuthToken = accessToken => {
    if (accessToken) {
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    } else {
        delete axiosInstance.defaults.headers.common['Authorization'];
    }
};

export default axiosInstance;