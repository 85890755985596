import React, { useEffect, useState } from 'react';
import axiosInstance, { setAuthToken } from './AxiosInstance';
import SettingsPage from './Components/SettingsPage';
import Loader from './Components/Loader';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';

const App = () => {
    const [loading, setLoading] = useState(true);
    const [authorizing, setAuthorizing] = useState(true);
    const [accessToken, setAccessToken] = useState(null);
    const [ttl, setTokenTtl] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const urlToken = params.get('token');

        if (urlToken) {
            authorize(urlToken);
        } else {
            setLoading(false);
            setAuthorizing(false);
        }
    }, []);

    const authorize = (token) => {
        setAuthorizing(true);
        axiosInstance.get('/api/auth', {
            params: { token }
        })
        .then(response => {
            const { accessToken, ttl } = response.data;
            setAccessToken(accessToken);
            setTokenTtl(ttl);
            setAuthToken(accessToken); // axiosInstance
            setAuthorizing(false);
            setLoading(false);
            navigate('/settings');
        })
        .catch(error => {
            console.error('Error during authorization:', error);
            setAuthorizing(false);
            setLoading(false);
        });
    };

    useEffect(() => {
        let timer;
        if (!authorizing && ttl) {
            timer = setTimeout(() => authorize(paramsToken), 10000);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [authorizing, ttl]);

    return (
        <div>
            {loading || authorizing || !accessToken ? (
                <Loader loading={true} />
            ) : (
                <SettingsPage />
            )}
        </div>
    );
};

const AppWrapper = () => (
  <Router>
      <Routes>
          <Route path="/" element={<App />} />
          <Route path="/settings" element={<SettingsPage />} />
      </Routes>
  </Router>
);

export default AppWrapper;