import React, { useState, useEffect } from 'react';
import axiosInstance from '../AxiosInstance';
import { BASE_URL } from '../config';
import './SettingsPage.css';

const SettingsPage = () => {
    const [refId, setRefId] = useState('');
    const [key, setKey] = useState('');
    const [country, setCountry] = useState('');
    const [region, setRegion] = useState('');
    const [town, setTown] = useState('');
    const [street, setStreet] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [fullName, setFullName] = useState('');
    const [errors, setErrors] = useState([]);
    const [successMessages, setSuccessMessages] = useState([]);

    useEffect(() => {
        fetchSettings();
    }, []);

    const fetchSettings = async () => {
        try {
            const response = await axiosInstance.get(`${BASE_URL}/api/settings`);
            setRefId(response.data.refId);
            setKey(response.data.key);
            setCountry(response.data.address.country);
            setRegion(response.data.address.region);
            setTown(response.data.address.town);
            setStreet(response.data.address.street);
            setZipCode(response.data.address.zipCode);
            setEmail(response.data.address.email);
            setPhoneNumber(response.data.address.phoneNumber);
            setFullName(response.data.address.fullName);
        } catch (error) {
            handleApiError(error);
        }
    };

    const handleRefIdChange = (event) => {
        setRefId(event.target.value);
    };

    const handleKeyChange = (event) => {
        setKey(event.target.value);
    };

    const handleCountryChange = (event) => {
        setCountry(event.target.value);
    };

    const handleRegionChange = (event) => {
        setRegion(event.target.value);
    };

    const handleTownChange = (event) => {
        setTown(event.target.value);
    };

    const handleZipCodeChange = (event) => {
        setZipCode(event.target.value);
    };

    const handleStreetChange = (event) => {
        setStreet(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePhoneChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    const handleFullNameChange = (event) => {
        setFullName(event.target.value);
    };

    const handleSaveSettings = async () => {
        try {
            const response = await axiosInstance.post(`${BASE_URL}/api/settings/save`, {
                key,
                refId,
                country,
                region,
                town,
                zipCode,
                street,
                email,
                phoneNumber,
                fullName
            });
            if (response.data && response.data.isError === false) {
                showSuccess('Settings saved successfully.');
                setErrors([]); // Clear any previous errors
            } else {
                handleApiError(response);
            }
        } catch (error) {
            handleApiError(error);
        }
    };

    const handleApiError = (error) => {
        if (error.data && error.data.status >= 400 && error.data.status < 500) {
            showError('Bad request error');
        } else if (error && error.data && error.data.isError) {
            showError(error.data.errorMessage);
        } else {
            showError('An unexpected error occurred. Please try again later.');
        }
    };

    const showSuccess = (message) => {
        const id = new Date().getTime();
        setSuccessMessages((prevMessages) => [...prevMessages, { id, message }]);
        setTimeout(() => {
            setSuccessMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== id));
        }, 5000); // Hide success message after 5 seconds
    };

    const showError = (message) => {
        const id = new Date().getTime();
        setErrors((prevErrors) => [...prevErrors, { id, message }]);
        setTimeout(() => {
            setErrors((prevErrors) => prevErrors.filter((error) => error.id !== id));
        }, 5000); // Hide error message after 5 seconds
    };

    return (
        <div className="settings-container">
            <div className="settings-content">
                <h2>Settings</h2>
                <form>
                    <div className="input-group">
                        <label>REFID:</label>
                        <input
                            type="text"
                            className="input-field"
                            value={refId}
                            onChange={handleRefIdChange}
                        />
                    </div>
                    <div className="input-group">
                        <label>KEY:</label>
                        <input
                            type="password"
                            className="input-field"
                            value={key}
                            onChange={handleKeyChange}
                        />
                    </div>
                </form>
            </div>

            <div className="address-sidebar">
                <h2> Address</h2>
                <form>
                    <div className="input-group">
                        <label>Country:</label>
                        <input
                            type="text"
                            className="input-field"
                            value={country}
                            onChange={handleCountryChange}
                        />
                    </div>
                    <div className="input-group">
                        <label>Region:</label>
                        <input
                            type="text"
                            className="input-field"
                            value={region}
                            onChange={handleRegionChange}
                        />
                    </div>
                    <div className="input-group">
                        <label>Town:</label>
                        <input
                            type="text"
                            className="input-field"
                            value={town}
                            onChange={handleTownChange}
                        />
                    </div>
                    <div className="input-group">
                        <label>Zip Code:</label>
                        <input
                            type="text"
                            className="input-field"
                            value={zipCode}
                            onChange={handleZipCodeChange}
                        />
                    </div>
                    <div className="input-group">
                        <label>Street:</label>
                        <input
                            type="text"
                            className="input-field"
                            value={street}
                            onChange={handleStreetChange}
                        />
                    </div>
                    <div className="input-group">
                        <label>Phone Number:</label>
                        <input
                            type="text"
                            className="input-field"
                            value={phoneNumber}
                            onChange={handlePhoneChange}
                        />
                    </div>
                    <div className="input-group">
                        <label>Email:</label>
                        <input
                            type="text"
                            className="input-field"
                            value={email}
                            onChange={handleEmailChange}
                        />
                    </div>
                    <div className="input-group">
                        <label>Full Name:</label>
                        <input
                            type="text"
                            className="input-field"
                            value={fullName}
                            onChange={handleFullNameChange}
                        />
                    </div>
                </form>
            </div>

            <div className="button-container">
                <button type="button" onClick={handleSaveSettings}>Save</button>
            </div>

            <div className="messages-container">
                {successMessages.map((msg) => (
                    <div key={msg.id} className="success-message">
                        {msg.message}
                    </div>
                ))}
                {errors.map((error) => (
                    <div key={error.id} className="error-message">
                        {error.message}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SettingsPage;